import { config as staticConfig, useConfig } from '@hp/config';
import {
  Country,
  getSpecificCfg,
  ParcelSize,
  PickupType,
} from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { gpsFieldsArray, PlaceResult, useGooglePlacesApi } from '@hp/form';
import { inlineKeys, useI18n } from '@hp/locale';
import { useFlow } from '@hp/seo';
import {
  getDeliveryDate,
  hasFeature,
  pickupDateOptions,
  useDebouncedCallback,
  useEffectSaveForm,
  useFetchedData,
} from '@hp/utils';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  HowToSendFormFields,
  ParcelFormProps,
  ParcelShopFilter,
  ParcelShopFilterWithFormSpecs,
  ParcelShopItemType,
  PickupFormType,
} from '../../types';
import { useHowToSendValidationSchema } from '../HowToSendForm/useHowToSendValidationSchema';
import { formatGoogleAddress } from './formatGoogleAddress';

type QuestRestApiResponse = {
  pickupPoints: ParcelShopItemType[];
};

const createSearchQuery = (params: ParcelShopFilter) => {
  return Object.entries(params)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

type Mode = 'pickup' | 'delivery';

const useSearchUtils = (
  mode: Mode,
  parcelShopId: string,
  googlePlacesApi: ReturnType<typeof useGooglePlacesApi>,
  timeout = 300,
) => {
  const {
    config: {
      defaults,
      other: { parcelShopSearchByAddressApiUrl },
    },
  } = useConfig();
  const defaultParcelShopDistance = defaults?.defaultParcelShopDistance || 50;
  const fetchApi = useFetchedData<QuestRestApiResponse>();
  const fetchData = useDebouncedCallback(fetchApi.fetchData, timeout);
  const [searchParams, setSearchParams] = useState<ParcelShopFilter>({});
  const [openItem, setOpenItem] = useState<ParcelShopItemType>(null);
  const [listOpen, setListOpen] = useState(false);
  const [openItemDetail, setOpenItemDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ParcelShopItemType | string>(
    parcelShopId,
  );

  const updateFilter = (searchFilter: ParcelShopFilter) => {
    if (!searchFilter) return;
    setSearchParams({
      ...searchFilter,
    });
  };

  useEffect(() => {
    if (
      !searchParams.address &&
      (!searchParams.latitude || !searchParams.longitude)
    )
      return;
    if (searchParams.latitude)
      searchParams.distance = defaultParcelShopDistance;
    searchParams.onlineCodAllowed = searchParams.cardPaymentAllowed;

    if (!hasFeature('allowLockers')) searchParams.type = 'parcelShop';

    if (mode === 'pickup') searchParams.dropOffAllowed = true;
    const withLimit = {
      limit: defaults.parcelShopsListSize,
      ...searchParams /* searchParams can contains limit itself and its wins */,
    };
    const paramsStr = createSearchQuery(withLimit);

    const newUrl = `${parcelShopSearchByAddressApiUrl}?${paramsStr}`;
    fetchData(newUrl);
  }, [searchParams]);

  const dataLoaded = !!fetchApi.data?.pickupPoints;
  const items = fetchApi.data?.pickupPoints ?? [];

  const refs = {
    search: useRef(null),
    list: useRef(null),
    input: useRef(null),
  };
  const handleOutsideClick = useCallback(
    (e) => {
      if (
        !openItemDetail &&
        !refs.search?.current?.contains(e.target) &&
        !refs.list?.current?.contains(e.target)
      ) {
        setListOpen(false);
      }
    },
    [openItemDetail],
  );
  const { registerInput, unregisterInput } = googlePlacesApi;

  const focusHandler = (
    filter: ParcelShopFilter,
    setSiblingListOpen: typeof setListOpen,
  ) => {
    if (filter?.latitude) {
      if (dataLoaded) {
        //  GPS has been choosen & data loaded, do not show google autocomplete --> unregister
        unregisterInput(refs.input);
      } else {
        //GPS is available, but data was not loaded (e.g. after refresh)
        updateFilter(filter);
      }
      setListOpen(true);
      setSiblingListOpen(false);
    }
  };

  const placeSelectedHandler = (
    place: PlaceResult,
    formikRef,
    setSiblingListOpen: typeof setListOpen,
  ) => {
    if (!place?.geometry) return;
    const {
      geometry: {
        location: { lat, lng },
      },
      formatted_address,
      address_components,
    } = place;

    const formattedAddress = formatGoogleAddress(
      address_components,
      formatted_address,
    );

    //because this function is stored in google api, we need access formik via ref
    const formikViaRef = formikRef.current;

    const newValue: ParcelShopFilterWithFormSpecs = {
      filter: {
        ...formikViaRef.values[
          mode === 'pickup' ? 'pickupFilter' : 'deliveryFilter'
        ].filter,
        latitude: lat(),
        longitude: lng(),
      },
      addressText: formattedAddress,
      realTypedText:
        formikViaRef.values[
          mode === 'pickup' ? 'pickupFilter' : 'deliveryFilter'
        ].addressText,
    };
    formikViaRef.setFieldValue(
      mode === 'pickup' ? 'pickupFilter' : 'deliveryFilter',
      newValue,
    );
    setListOpen(true);
    setSiblingListOpen(false);
  };
  const changeHandler = (formikRef, setSiblingListOpen: typeof setListOpen) => {
    //because registerInput register function only firsttime (google api keeps handler function), we need provide formik by reference.
    const placeHandlerWithFormikRef = (place) =>
      placeSelectedHandler(place, formikRef, setSiblingListOpen);
    registerInput(refs.input, placeHandlerWithFormikRef, gpsFieldsArray);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [listOpen, handleOutsideClick]);

  const formError =
    !parcelShopId && !listOpen
      ? mode === 'pickup'
        ? inlineKeys.pickupPlaceMustBeChoosen.trans
        : inlineKeys.deliveryPlaceMustBeChoosen.trans
      : null;

  return {
    ...fetchApi,
    updateFilter: useCallback(updateFilter, []),
    searchParams,
    setSearchParams,
    formError,
    items,
    dataLoaded,
    refs,
    openItem,
    setOpenItem,
    selectedItem,
    setSelectedItem,
    openItemDetail,
    setOpenItemDetail,
    listOpen,
    setListOpen,
    focusHandler,
    changeHandler,
  };
};

export const useDelivery = (
  howToSend: HowToSendFormFields,
  parcel: ParcelFormProps,
  saveHowToSend: (value: React.SetStateAction<HowToSendFormFields>) => void,
) => {
  const i18n = useI18n();
  const flow = useFlow();
  const { language } = useLanguage();
  const { config } = useConfig();
  const supportedDeliveryShops = getSpecificCfg(
    config.constraints.deliveryParcelShops,
    howToSend.sendToCountry,
    true,
  );
  const supportedDeliveryCourier = getSpecificCfg(
    config.constraints.deliveryCourier,
    howToSend.sendToCountry,
    true,
  );

  const hasXL = parcel?.items?.some((x) => x.size === ParcelSize.XL);
  const hasL = parcel?.items?.some((x) => x.size === ParcelSize.L);
  const isMulti = parcel?.items?.length > 1;

  const supportedDeliveries = [
    supportedDeliveryShops && !hasXL && !isMulti
      ? PickupFormType.PARCELSHOP_OR_BOX
      : null,
    supportedDeliveryCourier ? PickupFormType.COURIER : null,
  ].filter((x) => x);

  const supportedPickups =
    hasXL || isMulti
      ? [PickupFormType.COURIER]
      : [PickupFormType.PARCELSHOP_OR_BOX, PickupFormType.COURIER];

  const abroad =
    howToSend.sendToCountry !== (staticConfig.app.defaultCountry as Country);

  const showDeliverySwitch = supportedDeliveries.length >= 2;
  const showPickupSwitch = supportedPickups.length >= 2;
  const showAbroad = abroad && !showDeliverySwitch && showPickupSwitch;

  const googlePlacesApi = useGooglePlacesApi();
  const pickup = useSearchUtils('pickup', howToSend.pickupId, googlePlacesApi);
  const delivery = useSearchUtils(
    'delivery',
    howToSend.deliveryId,
    googlePlacesApi,
  );

  const validationSchema = useHowToSendValidationSchema(true);
  const handleSubmit = async (values: HowToSendFormFields) => {
    if (values.deliveryFormType === PickupFormType.COURIER) {
      values.deliveryType = PickupType.COURIER;
      delete values.deliveryId;
    }
    if (values.pickupFormType === PickupFormType.COURIER) {
      values.pickupType = PickupType.COURIER;
      delete values.pickupId;
    }
    saveHowToSend(values);
    flow.nextPage('orderRecipient');
  };

  const formik = useFormik<HowToSendFormFields>({
    initialValues: howToSend,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnMount: false,
  });

  const formikRef = useRef<typeof formik>();
  formikRef.current = formik;

  const pickupOptions = pickupDateOptions(howToSend.sendFromCountry, i18n);
  const pickupOptionsWithDayNames = pickupDateOptions(
    howToSend.sendFromCountry,
    i18n,
    true,
    language,
  );

  const selectedDateOption =
    pickupOptions.find((x) => x.value === formik.values.sendWhen) ||
    pickupOptions[0];

  const deliveryDate = getDeliveryDate(
    selectedDateOption.date,
    howToSend.sendFromCountry,
    howToSend.sendToCountry,
    config,
    i18n,
  );

  useEffectSaveForm(formik, saveHowToSend);

  useEffect(() => {
    if (!showDeliverySwitch) {
      formik.setFieldValue('deliveryFormType', supportedDeliveries[0]);
    }
  }, [showDeliverySwitch]);
  useEffect(() => {
    if (!showPickupSwitch) {
      formik.setFieldValue('pickupFormType', supportedPickups[0]);
    }
  }, [showPickupSwitch]);

  useEffect(() => {
    if (typeof pickup.selectedItem !== 'string')
      formik.setFieldValue('pickupId', pickup.selectedItem?.id);
  }, [pickup.selectedItem]);

  useEffect(() => {
    if (typeof delivery.selectedItem !== 'string')
      formik.setFieldValue('deliveryId', delivery.selectedItem?.id);
  }, [delivery.selectedItem]);

  useEffect(() => {
    pickup.updateFilter(formikRef?.current?.values?.pickupFilter?.filter);
  }, [formik.values.pickupFilter?.filter]);
  useEffect(() => {
    delivery.updateFilter(formikRef?.current?.values?.deliveryFilter?.filter);
  }, [formik.values.deliveryFilter?.filter]);

  // cleanup when input is not used
  useEffect(() => {
    if (
      formik.values.pickupFormType === PickupFormType.COURIER ||
      pickup.selectedItem
    ) {
      googlePlacesApi.unregisterInput(pickup.refs.input);
    }
  }, [formik.values.pickupFormType, pickup.selectedItem]);

  // cleanup when delivery is not used
  useEffect(() => {
    if (
      formik.values.deliveryFormType === PickupFormType.COURIER ||
      delivery.selectedItem
    ) {
      googlePlacesApi.unregisterInput(delivery.refs.input);
    }
  }, [formik.values.deliveryFormType, delivery.selectedItem]);

  return {
    pickup,
    delivery,
    formik,
    /** useful for useEffect, because formik is a new reference*/
    formikRef,
    pickupOptionsWithDayNames,
    deliveryDate,
    selectedDateOption,
    language,
    supportedPickups,
    showAbroad,
    showDeliverySwitch,
    showPickupSwitch,
    hasL,
    hasXL,
    isMulti,
  };
};
