import {
  ArrowIcon,
  Button,
  ButtonType,
  ConditionalWrapper,
  FlexBox,
  FlexItem,
  H2,
  Hr,
  Strong,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Money, MoneySymbolIcon, ParcelDescription } from '@hp/components';
import { useConfig } from '@hp/config';
import { Country, getSpecificCfg, PickupType } from '@hp/core/shared';
import {
  CheckboxField,
  FormBackButton,
  FormWrapper,
  SelectField,
  TextField,
} from '@hp/form';
import { SpaceBetweenRow } from '@hp/layout';
import { fields } from '@hp/locale';
import { colors } from '@hp/theme';
import {
  hasFeature,
  useEffectSaveForm,
  useEffectValidateOnInitForm,
  useObjectLocalStorage,
} from '@hp/utils';
import { Trans } from '@lingui/react';
import { NameOfPath } from 'easy-tsnameof';
import { FieldArray, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { sharedKeys } from '../../sharedKeys';
import {
  AdditionalServicesFormFields,
  ParcelFormProps,
  RecipientFormFields,
  SenderFormFields,
} from '../../types';
import { computePrice } from '../../utils';
import {
  DescriptionParagraph,
  InsuranceCheckboxWrapper,
  InsuranceInputWrapper,
  InsuranceRow,
} from './styled';
import { useAdditionalServicesForm } from './useAdditionalServicesForm';
import { useAdditionalServicesValidationSchema } from './useAdditionalServicesValidationSchema';

const formId = 'additionalServicesForm';

export const AdditionalServicesForm: FC = () => {
  const [sender] = useObjectLocalStorage<SenderFormFields>('sender');
  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel');
  const [recipient] = useObjectLocalStorage<RecipientFormFields>('recipient');
  const { config } = useConfig();

  const {
    initialValues,
    bankCodes,
    saveFormData,
    handleSubmit,
    isSupportedCashOnDelivery,
    howToSend,
    emptyValues,
  } = useAdditionalServicesForm();

  const formik = useFormik<AdditionalServicesFormFields>({
    initialValues,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: useAdditionalServicesValidationSchema(
      howToSend.sendToCountry,
    ),
  });

  useEffectSaveForm(formik, saveFormData);
  useEffectValidateOnInitForm(formik, emptyValues);
  const multipleParcel = parcel.items.length > 1;

  /**
   * Clear COD data according to selected sendToCountry manually because
   * COD currency is determined based on it and only relevant bank info
   * should be saved with the case.
   *
   * NOTE: Only accountNumber + bankCode should be saved when sending to CZ
   *       Only iban + swift should be saved when sending to SK
   */
  useEffect(() => {
    formik.values.accountNumber =
      howToSend.sendToCountry === Country.CZ
        ? formik.values.accountNumber
        : undefined;
    formik.values.bankCode =
      howToSend.sendToCountry === Country.CZ
        ? formik.values.bankCode
        : undefined;
    formik.values.iban =
      howToSend.sendToCountry === Country.SK ? formik.values.iban : undefined;
    formik.values.swift =
      howToSend.sendToCountry === Country.SK ? formik.values.swift : undefined;
  }, [howToSend.sendToCountry]);

  const price = computePrice(
    {
      howToSend,
      parcel,
      recipient,
      sender,
      additionalServices: formik.values,
    },
    config,
  );

  const specsList = getSpecificCfg(
    config.parcelSpecifications,
    howToSend.sendToCountry,
  );

  const totalParcelValues = formik.values.parcelValues?.reduce(
    (acc, parcel) => acc + (parcel?.value ?? 0),
    0,
  );

  return (
    <FormWrapper formik={formik} flex marginBottomSize="xl">
      <H2>
        <Trans id="order.additionalServices.addServices">
          Mohlo by se vám hodit
        </Trans>
      </H2>

      {sender?.pickup === PickupType.COURIER && (
        <>
          <CheckboxField<AdditionalServicesFormFields>
            formId={formId}
            name="fragile"
            label={
              <>
                {sharedKeys.parcelTags.fragile} (+{' '}
                <Money currency="CZK">{0}</Money>)
              </>
            }
            data-test="additionalServices.checkbox.fragile"
            disabled={formik.isSubmitting}
          />

          {!formik.values.fragile && (
            <DescriptionParagraph>
              <Typography type={TypographyType.BodyMicroLight}>
                <Trans id="order.additionalServices.tag.fragile.description">
                  Pokud posíláte sklo, porcelán, tekutiny nebo zboží vysoké
                  hodnoty.
                </Trans>
              </Typography>
            </DescriptionParagraph>
          )}

          <CheckboxField<AdditionalServicesFormFields>
            formId={formId}
            name="doNotTip"
            label={
              <>
                {sharedKeys.parcelTags.doNotTip} (+{' '}
                <Money currency="CZK">{0}</Money>)
              </>
            }
            data-test="additionalServices.checkbox.doNotTip"
            disabled={formik.isSubmitting}
          />

          {!formik.values.doNotTip && (
            <DescriptionParagraph>
              <Typography type={TypographyType.BodyMicroLight}>
                <Trans id="order.additionalServices.tag.doNotTip.description">
                  Pokud posíláte věci, které jsou citlivé na změnu polohy.
                </Trans>
              </Typography>
            </DescriptionParagraph>
          )}
        </>
      )}

      {isSupportedCashOnDelivery && (
        <CheckboxField<AdditionalServicesFormFields>
          formId={formId}
          name="cashOnDelivery"
          label={
            <>
              {sharedKeys.cashOnDelivery} (+{' '}
              <Money currency="CZK">
                {
                  getSpecificCfg(config.priceList, howToSend.sendToCountry)
                    .cashOnDelivery
                }
              </Money>
              )
            </>
          }
          data-test="additionalServices.checkbox.cashOnDelivery"
          disabled={formik.isSubmitting}
        />
      )}

      {formik.values.cashOnDelivery && (
        <>
          <TextField<AdditionalServicesFormFields>
            formId={formId}
            name="cashOnDeliveryValue"
            label={sharedKeys.cashOnDeliveryValue}
            type="number"
            step={1}
            noCheckMark
            data-test="additionalServices.input.cashOnDelivery"
            customIcon={
              <MoneySymbolIcon
                currency={
                  howToSend.sendToCountry === Country.CZ ? 'CZK' : 'EUR'
                }
              />
            }
            disabled={formik.isSubmitting}
          />
          {howToSend.sendToCountry === Country.CZ ? (
            <FlexBox nowrap itemsPadding="zero">
              <FlexItem width={['auto', 'auto']}>
                <TextField<AdditionalServicesFormFields>
                  formId={formId}
                  name="accountNumber"
                  label={fields.accountNumberCZ}
                  format={(bankAccount) => bankAccount?.replace(/\ /g, '')}
                  testId="additionalServices.input.accountNumber"
                  disabled={formik.isSubmitting}
                />
              </FlexItem>

              <FlexItem width={['100px', 'auto']} marginLeft="s">
                <SelectField<AdditionalServicesFormFields>
                  formId={formId}
                  name="bankCode"
                  label={fields.bankCode}
                  firstOption={true}
                  options={bankCodes}
                  noCheckmark
                  data-test="homepage.input.sendFromCountry"
                  valueView={(bankCode) => bankCode}
                  disabled={formik.isSubmitting}
                />
              </FlexItem>
            </FlexBox>
          ) : (
            <FlexBox nowrap itemsPadding="zero">
              <FlexItem width={['auto', 'auto']}>
                <TextField<AdditionalServicesFormFields>
                  formId={formId}
                  name="iban"
                  label={fields.iban}
                  disabled={formik.isSubmitting}
                />
              </FlexItem>

              <FlexItem width={['100px', 'auto']} marginLeft="s">
                <TextField<AdditionalServicesFormFields>
                  formId={formId}
                  name="swift"
                  label={fields.swift}
                  disabled={formik.isSubmitting}
                />
              </FlexItem>
            </FlexBox>
          )}

          <CheckboxField<AdditionalServicesFormFields>
            formId={formId}
            name="addPaymentDetails"
            label={fields.addPaymentDetails}
            data-test="additionalServices.checkbox.addVariableSymbol"
            disabled={formik.isSubmitting}
          />

          {formik.values.addPaymentDetails && (
            <>
              <TextField<AdditionalServicesFormFields>
                formId={formId}
                name="variableSymbol"
                label={fields.variableSymbol}
                testId="additionalServices.input.variableSymbol"
                disabled={formik.isSubmitting}
              />
            </>
          )}
        </>
      )}

      {isSupportedCashOnDelivery && !formik.values.cashOnDelivery && (
        <DescriptionParagraph>
          <Typography type={TypographyType.BodyMicroLight}>
            <Trans id="order.additionalServices.tag.cashOnDelivery.description">
              Peníze převedeme na váš účet do 5 pracovních dní od doručení
              balíku.
            </Trans>
          </Typography>
        </DescriptionParagraph>
      )}

      <CheckboxField<AdditionalServicesFormFields>
        formId={formId}
        name="insurance"
        label={sharedKeys.insuranceOverMax(config)}
        data-test="additionalServices.checkbox.insuranceOverMax"
        disabled={formik.isSubmitting}
      />
      {formik.values.insurance ? (
        <>
          <FieldArray
            name="parcelValues"
            render={() => (
              <>
                {parcel.items?.map((item, index) => (
                  <InsuranceRow
                    key={index}
                    noMargin={parcel?.items.length === 1}
                  >
                    {multipleParcel && (
                      <InsuranceCheckboxWrapper>
                        <CheckboxField<AdditionalServicesFormFields>
                          formId={formId}
                          name={
                            `parcelValues.${index}.on` as NameOfPath<
                              AdditionalServicesFormFields
                            >
                          }
                          label={
                            <ParcelDescription
                              index={index}
                              {...item}
                              specsList={specsList}
                            />
                          }
                          data-test="additionalServices.checkbox.insuranceOverMax"
                          disabled={formik.isSubmitting}
                        />
                      </InsuranceCheckboxWrapper>
                    )}
                    {formik.values.parcelValues?.[index]?.on && (
                      <ConditionalWrapper
                        condition={multipleParcel}
                        wrapper={(children) => (
                          <InsuranceInputWrapper>
                            {children}
                          </InsuranceInputWrapper>
                        )}
                      >
                        <TextField<AdditionalServicesFormFields>
                          formId={formId}
                          name={
                            `parcelValues.${index}.value` as NameOfPath<
                              AdditionalServicesFormFields
                            >
                          }
                          convert={
                            (v) => (v ? v : null) /* '' and zeros to NULL */
                          }
                          validateImmediately={false}
                          label={
                            <Trans id="common.placeholder.parcelValue">
                              Hodnota zboží
                            </Trans>
                          }
                          type="number"
                          noCheckMark
                          data-test="additionalServices.input.parcelValues"
                          customIcon={<MoneySymbolIcon currency="CZK" />}
                          disabled={formik.isSubmitting}
                        />
                      </ConditionalWrapper>
                    )}
                  </InsuranceRow>
                ))}
              </>
            )}
          />

          <Typography type={TypographyType.BodyMicroLight}>
            {hasFeature('multiPackage') ? (
              <Trans id="order.additionalServices.insurenceValue.mps">
                Celková cena připojištění{' '}
                <Money currency="CZK">
                  {totalParcelValues > 50000 ? price.extraInsurance : 0}
                </Money>
              </Trans>
            ) : (
              <Trans id="order.additionalServices.insurenceValue">
                Cena připojištění{' '}
                <Money currency="CZK">
                  {totalParcelValues > 50000 ? price.extraInsurance : 0}
                </Money>
              </Trans>
            )}
          </Typography>
        </>
      ) : (
        <DescriptionParagraph>
          <Typography type={TypographyType.BodyMicroLight}>
            <Trans id="order.additionalServices.insurance.description">
              Každý jednotlivý balík je{' '}
              <Strong>v ceně přepravy pojištěný do 50 000 Kč.</Strong> Pokud
              posíláte balík s hodnotou nad 50 000 Kč, doporučujeme využít
              připojištění.
            </Trans>
          </Typography>
        </DescriptionParagraph>
      )}

      <Hr />

      <SpaceBetweenRow>
        <div>{sharedKeys.price}</div>
        <Money currency="CZK">{price.total}</Money>
      </SpaceBetweenRow>

      <SpaceBetweenRow marginSize="m" noBottomMargin>
        <FormBackButton
          to="orderSender"
          type="button"
          className="gaButton gaButtonBack_additionalServices"
          buttonType={ButtonType.TEXT}
          data-test="additionalServices.button.back"
        >
          <Trans id="common.button.back">Zpět</Trans>
        </FormBackButton>
        <Button
          bottom
          type="submit"
          className="gaButton gaButtonSubmit_additionalServices"
          fakeDisabled={!formik.isValid}
          buttonType={ButtonType.PRIMARY}
          icon={
            <ArrowIcon
              color={!formik.isValid ? colors.gray_warm : colors.white}
            />
          }
          data-test="additionalServices.button.submit"
          animate={formik.isValid}
        >
          <Trans id="common.button.continue">Pokračovat</Trans>
        </Button>
      </SpaceBetweenRow>
    </FormWrapper>
  );
};
