import { breakpoints, elements, spacing } from '@hp/theme';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-left: ${spacing.l};
  width: ${elements.buttonMaxWidth};
`;

export const DescriptionParagraph = styled.p`
  margin: 0 ${spacing.s} ${spacing.xs} 30px;
`;

export const InsuranceRow = styled.div<{ noMargin?: boolean }>`
  margin-left: ${({ noMargin }) => (noMargin ? '0' : '28px')};
  width: 100%;
`;

export const InsuranceCheckboxWrapper = styled.div``;

export const InsuranceInputWrapper = styled.div`
  flex-grow: 1;
  margin-top: ${spacing.s};
  @media (min-width: ${breakpoints.minDesktop}) {
    max-width: 220px;
  }
`;
